import { useAuth } from '@clerk/nextjs';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import { AUTH_MODAL_OPEN, AUTH_MODAL_TAB } from '@/shared/constant';

export const useAuthModal = (): [
  isAuthModalOpen: boolean,
  handleIsAuthModalOpen: (value: boolean) => void,
] => {
  const { isSignedIn } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(
    !!localStorage.getItem(AUTH_MODAL_OPEN),
  );

  const handleIsAuthModalOpen = (isOpen: boolean) => {
    setIsAuthModalOpen(isOpen);
    if (isOpen) {
      localStorage.setItem(AUTH_MODAL_OPEN, 'true');
    } else {
      localStorage.removeItem(AUTH_MODAL_OPEN);
    }
  };

  useEffect(() => {
    if (isSignedIn && isAuthModalOpen) {
      handleIsAuthModalOpen(false);
    }
  }, [isSignedIn]);

  return [isAuthModalOpen, handleIsAuthModalOpen];
};

export const useAuthContainer = (
  propsTab: 'login' | 'register',
): [tab: string, setTab: Dispatch<SetStateAction<string>>] => {
  const [tab, setTab] = useState(
    localStorage.getItem(AUTH_MODAL_TAB) || propsTab || 'login',
  );

  useEffect(() => {
    localStorage.setItem(AUTH_MODAL_TAB, tab);
    return () => {
      localStorage.removeItem(AUTH_MODAL_TAB);
    };
  }, [tab]);

  return [tab, setTab];
};

export const useAuthVisible = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { isSignedIn } = useAuth();

  const checkVisibility = () => {
    if (window.location.hash === '') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', checkVisibility);
    // Initial check
    checkVisibility();
    return () => {
      window.removeEventListener('hashchange', checkVisibility);
    };
  }, []);

  return !isSignedIn && isVisible;
};
