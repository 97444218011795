import { useAuth } from '@clerk/nextjs';
import { useFeature } from '@growthbook/growthbook-react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';

import AuthModal from '@/components/auth/AuthModal/AuthModal';
import CartContainer from '@/components/cart/CartContainer/CartContainer';
import CartHeader from '@/components/cart/CartHeader/CartHeader';
import Loader from '@/components/common/Loader/Loader';
import CloseIcon from '@/components/icons/CloseIcon';
import SelectPlans from '@/components/subscription/selectPlans/SelectPlans';
import SuccessModal from '@/components/subscription/SuccessModal/SuccessModal';
import { useMediaQuery } from '@/hooks';
import { useAuthModal } from '@/hooks/useAuthModal';
import type { ProductModelType } from '@/models/api';
import { formatOrderPlan } from '@/models/helpers/order';
import { useStore } from '@/models/root-store';
import { AUTH_NAVIGATION_TARGET } from '@/shared/constant';
import { Experiments, FEATURE_FLAGS, Mode } from '@/utils/constants';
import { trackRecommendationViewed } from '@/utils/track/tracker.helper';

import { ExitModal } from '../ExitModal/ExitModal';
import { MoreThanOneWLPModal } from '../MoreThanOneWLPModal/MoreThanOneWLPModal';
import NewUpsellHeader from '../UpsellHeader/UpsellHeader';
import { UpsellsProductContainer } from '../UpsellsProductContainer/UpsellsProductContainer';
import UpsellsProductMobileContainer from '../UpsellsProductMobileContainer/UpsellsProductMobileContainer';

const CartSlidingPane = observer(() => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useAuthModal();
  const router = useRouter();
  const { isSignedIn: isLoggedIn } = useAuth();

  const {
    cartStore,
    productStore: {
      productsLookup,
      search: { resetSearch },
    },
    generalStore: { MOV, SMOV, getContentByKey },
    customerStore: { isAnon, customer },
    subscriptionStore,
  } = useStore();
  const content = getContentByKey('upsell');
  const isMobile = useMediaQuery(`(max-width:640px)`);
  const { cart, draftCart, cartToUse } = cartStore;
  const MinOrderValue = draftCart.mode === Mode.EDIT_SUBSCRIPTION ? SMOV : MOV;
  const cartLogin = router?.query?.cartLogin;
  const [showExitModal, setShowExitModal] = useState(false);
  const [showWLPModal, setShowWLPModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDesktopUpsellOpen, setIsDesktopUpsellOpen] = useState(false);
  const [isMobileUpsellOpen, setIsMobileUpsellOpen] = useState(false);
  const [upsells, setUpsells] = useState([] as any);
  const [isNewUpsellEnabled, setIsNewUpsellEnabled] = useState(false);
  const [upsellsLoading, setUpsellsLoading] = useState(false);
  const [sampleImages, setSampleImages] = useState([]);
  const variant = useFeatureFlagVariantKey('upsell-experiment');
  const cartContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (variant === undefined) return;
    const newUpsellEnabled = variant === FEATURE_FLAGS.NEW_UPSELLS;
    setIsNewUpsellEnabled(newUpsellEnabled);
    if (cart.isCartOpen) {
      try {
        trackRecommendationViewed('upsell_experiment', 'cart', 'cart');
      } catch (error) {
        // ignore error
      }
    }
  }, [variant]);

  // reset the frequency when cart is opened
  useEffect(() => {
    if (cart.isCartOpen) {
      cart.resetFrequency();
    }
  }, [cart.isCartOpen]);

  const {
    isCartOpen,
    openCart,
    isDefaultCartOpen,
    defaultCartOpen,
    closeCart,
    closeMealPlan,
    openMealPlan,
    isMealPlanOpen,
    moreThanOneWLPPlan,
    menuBuilderPlan,
  } = cart;

  const { subTotal, mode, isEmpty } = cartToUse;

  const showMobileNewUpsell =
    isMobileUpsellOpen && isMobile && isNewUpsellEnabled;
  const showDesktopNewUpsell =
    !isEmpty && !isMobile && isNewUpsellEnabled && !_.isEmpty(upsells);

  const styles = {
    closeIconStyle: {
      width: '24px !important',
    },
  };

  const proceedToCheckout = async () => {
    if (moreThanOneWLPPlan) {
      setShowWLPModal(true);
      return;
    }
    if (isLoggedIn) {
      cart.closeCart();
      router.push('/checkout');
    } else {
      sessionStorage.setItem('navigationFromCheckout', 'true');
      sessionStorage.setItem(AUTH_NAVIGATION_TARGET, '/checkout');
      cart.closeCart();
      router.push('/signin');
    }
  };

  const handleEditSubscription = async () => {
    setLoading(true);
    try {
      const plans = cartToUse.plans.map((plan: any) =>
        formatOrderPlan(plan, cartToUse.discount, true),
      );
      const result = await subscriptionStore.updatePlans(plans);
      if (!result) {
        return;
      }
      setIsSuccess(true);
      cart.closeCart();
      cartStore.resetDraftCart();
    } catch (error) {
      toast.error(
        'Oops! An error occurred while trying to save changes. Please try again or contact us for more help.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseOrShowExitModal = () => {
    const shouldShowExitModal = !router.pathname.includes('/menu');
    if (mode === Mode.EDIT_SUBSCRIPTION && shouldShowExitModal) {
      setShowExitModal(true);
    } else {
      closeCart();
    }
  };

  const handleCheckout = () => {
    if (isMobile && isNewUpsellEnabled) {
      if (_.isEmpty(upsells)) {
        proceedToCheckout();
      } else if (isMobileUpsellOpen) {
        setIsMobileUpsellOpen(false);
        proceedToCheckout();
      } else {
        setIsMobileUpsellOpen(true);
      }
    } else {
      setIsDesktopUpsellOpen(false);
      setTimeout(handleCloseOrShowExitModal, 500);
      proceedToCheckout();
    }
  };

  const checkoutOrSave = async () => {
    if (mode === Mode.EDIT_SUBSCRIPTION) {
      await handleEditSubscription();
    } else {
      handleCheckout();
    }
    resetSearch();
  };

  /** fetch upsells */
  const getUpSells = async () => {
    setUpsellsLoading(_.isEmpty(upsells));
    try {
      const guestCart = await cartStore.getGuestUpSellsQuery(cartToUse);
      const lineItems = menuBuilderPlan?.lineItems || [];
      const filteredUpsellsObj: string[] = [];
      setSampleImages(guestCart.sampleImages || []);
      guestCart.upsells.forEach((val: string) => {
        const match = lineItems.some((obj: any) => obj.product.sku === val);
        if (!match && productsLookup[val]) {
          filteredUpsellsObj.push(val);
        }
      });
      const productUpsells = filteredUpsellsObj
        .map((productSku: string) => productsLookup[productSku])
        .filter((prod: any) => prod) as ProductModelType[];
      setUpsells(productUpsells);
      setUpsellsLoading(false);
    } catch (error) {
      // Ignore error here
      setUpsellsLoading(false);
    }
  };

  const refreshUpSells = () => {
    if (!isEmpty) getUpSells();
    // scroll to bottom
    if (cartContainerRef.current) {
      const scrollableHeight = cartContainerRef.current.scrollHeight;
      cartContainerRef.current.scrollTo(0, scrollableHeight);
    }
  };

  const openNewUpsell = () => {
    refreshUpSells();
    setTimeout(() => {
      setIsDesktopUpsellOpen(true);
    }, 500);
  };

  /* on click on close cart icon */
  const onRequestClose = () => {
    if (isMobile && isMobileUpsellOpen) {
      setIsMobileUpsellOpen(false);
      refreshUpSells();
      return;
    }

    if (!isMobile && isDesktopUpsellOpen) {
      setIsDesktopUpsellOpen(false);
      setTimeout(handleCloseOrShowExitModal, 500);
      return;
    }

    handleCloseOrShowExitModal();
  };

  /* Exit Modal functions */
  const keepEditing = () => {
    setShowExitModal(false);
  };

  const closeWithoutSaving = () => {
    setShowExitModal(false);
    router.push({
      pathname: '/account',
    });
    closeCart();
    cartStore.resetDraftCart();
  };

  useEffect(() => {
    if (cartLogin && router.pathname !== '/signin' && !isAnon) {
      if (isLoggedIn) {
        defaultCartOpen();
        openCart();
      } else {
        router.replace('/signin?cartLogin=true');
      }
    }
  }, [cartLogin, isAnon, isDefaultCartOpen]);

  // Growthbook Flag
  const featureResult = useFeature(Experiments.APPLEPAY);
  const isApplePaySupported =
    'ApplePaySession' in window &&
    isLoggedIn &&
    mode !== Mode.EDIT_SUBSCRIPTION;
  const variation = isApplePaySupported && featureResult.value;

  const upsellTitle = customer.isNewCustomer
    ? content?.guestTitle || 'Boost your cart 🚀'
    : content?.returnCustomerTitle || 'Picked for you!';

  return (
    <div className="flex">
      <SlidingPane
        className="!w-full md:max-w-[392px]"
        overlayClassName="z-50"
        isOpen={isCartOpen}
        title={
          showMobileNewUpsell ? (
            <NewUpsellHeader title={upsellTitle} />
          ) : (
            <CartHeader
              total={subTotal}
              MOV={MinOrderValue}
              cartMode={mode}
              checkoutOrSave={checkoutOrSave}
              loading={loading}
              variation={variation}
              isValid={cart.isValid}
            ></CartHeader>
          )
        }
        closeIcon={
          showMobileNewUpsell ? (
            <div className="px-1" style={styles.closeIconStyle}>
              <Image
                id="back"
                src="/assets/icons/arrow_back.svg"
                alt="close"
                width={15}
                height={15}
              />
            </div>
          ) : (
            <div className="px-1" style={styles.closeIconStyle}>
              <CloseIcon />
            </div>
          )
        }
        onRequestClose={onRequestClose}
        onAfterOpen={openNewUpsell}
      >
        {showDesktopNewUpsell && (
          <div
            className={`upsell-slide fixed top-0 z-50 flex max-h-screen w-max flex-col border-r border-grey-liter bg-off-grey ${
              isDesktopUpsellOpen ? 'slide-out' : ''
            }`}
          >
            <div className="flex h-[64px] w-full shrink-0 items-center justify-center border-b border-grey-liter">
              <h4 className="font-interSemibold text-lg leading-6 text-black">
                {upsellTitle}
              </h4>
            </div>
            <div className="scrollbar-hide overflow-y-auto p-6 py-8">
              <UpsellsProductContainer
                productsList={upsells}
                loading={upsellsLoading}
              />
            </div>
          </div>
        )}
        <div className="relative z-60 h-full bg-white">
          <CartContainer
            closeCart={closeCart}
            checkoutOrSave={checkoutOrSave}
            openMealPlan={openMealPlan}
            MinOrderValue={MinOrderValue}
            loading={loading}
            variation={variation}
            upsells={upsells}
            refreshUpSells={refreshUpSells}
            sampleImages={sampleImages}
          />
        </div>
        <div
          className={`upsell-container fixed top-16 z-60 flex size-full bg-off-grey ${isMobileUpsellOpen ? 'slide-in' : 'slide-out'} ${isMobile ? '' : 'hidden'}`}
        >
          <UpsellsProductMobileContainer
            productsList={upsells}
            refreshUpSells={refreshUpSells}
            onRequestClose={checkoutOrSave}
            loading={upsellsLoading}
          />
        </div>
      </SlidingPane>

      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={() => setIsAuthModalOpen(false)}
        navigatedFrom="checkout"
        forceRedirectUrl="/checkout"
      />

      <SuccessModal
        title="Your subscription has been updated"
        isSuccess={isSuccess}
        isSuccessClose={() => {
          setIsSuccess(false);
          router.push('/account');
        }}
      />
      <ExitModal
        isOpen={showExitModal}
        keepEditing={keepEditing}
        closeWithoutSaving={closeWithoutSaving}
      />

      <MoreThanOneWLPModal
        isOpen={showWLPModal}
        closeModal={() => setShowWLPModal(false)}
      />

      <SelectPlans
        isOpen={isMealPlanOpen}
        closeMealPlan={closeMealPlan}
        closeCart={closeCart}
        mode={mode}
        showBottom={false}
      />
      {cart.loading && <Loader />}
    </div>
  );
});

export default CartSlidingPane;
