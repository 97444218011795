import React from 'react';
import { createPortal } from 'react-dom';

import LoadingContainer from '@/components/common/LoadingContainer/LoadingContainer';

const Loader = () => {
  return createPortal(
    <div className="fixed inset-0 isolate z-1001 block size-full !bg-black/80">
      <div className="flex size-full items-center justify-center">
        <LoadingContainer />
      </div>
    </div>,
    document.body,
  );
};

export default Loader;
