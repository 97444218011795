import Button from '@/components/common/Button/Button';
import { Mode } from '@/utils/constants';

export type CartHeaderTypes = {
  total: number;
  MOV: number;
  cartMode?: Mode | undefined;
  checkoutOrSave: () => void;
  loading: boolean;
  variation: boolean;
  isValid?: boolean;
};

const CartHeader: React.FC<CartHeaderTypes> = ({
  total,
  MOV,
  cartMode,
  checkoutOrSave,
  loading,
  variation,
  isValid,
}: CartHeaderTypes) => {
  const renderHeader = (mode: Mode | undefined) => {
    switch (mode) {
      case Mode.EDIT_SUBSCRIPTION:
        return 'Edit Order';
      default:
        return 'Your Cart';
    }
  };

  const renderText = (mode: Mode | undefined) => {
    switch (mode) {
      case Mode.EDIT_SUBSCRIPTION:
        return 'Save';
      default:
        return 'Checkout';
    }
  };

  return (
    <div className="z-50 flex w-full flex-row justify-between pr-3">
      <div className="flex items-center">
        <h2 className="font-interMedium text-lg leading-6 text-black">
          {renderHeader(cartMode)}
        </h2>
      </div>

      {variation ? (
        <div className="flex items-center">
          <p className="flex flex-col font-interBold text-xs tracking-wide">
            <p>
              <span className="pr-1 text-sm">Total ${total.toFixed(2)}</span>
            </p>
            {cartMode !== Mode.EDIT_SUBSCRIPTION && (
              <p className="pr-1 font-interMedium text-xxs text-grey-dark">
                {!(total >= MOV) ? (
                  <span>Min. order value ${MOV}</span>
                ) : (
                  <span>(Excl. Shipping)</span>
                )}
              </p>
            )}
          </p>
        </div>
      ) : (
        <div>
          <Button
            type="button"
            theme="primary"
            disabled={!(total >= MOV) || loading || !isValid}
            onClick={checkoutOrSave}
            loading={loading}
          >
            <span className="font-interMedium text-base">
              {renderText(cartMode)}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CartHeader;
